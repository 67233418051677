<template>
  <window-default ref="window" :resolve="find" :mock="mock" :props="props" v-slot="{data}" @loaded="(obj) => model = obj" @save="save" :proccess-data="proccessData">
    <window-body class="wrapper-xl">
      <e-row mr>
        <e-col size="1">
          <erp-s-field
              label="ID:"
            >
            <erp-input v-model="model.id" size="2" readonly />
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <e-col>
          <erp-s-field
              label="Descricao:"
          >
            <erp-input v-model="model.nome" autofocus size="2" />
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <e-col>
          <erp-s-field
              label="Ativo:"
          >
            <erp-checkbox v-model="model.active" />
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row>
        <e-col>
          <e-row class="m-t">
            <e-col class="form-column-align-center">
              <e-row class="m-t-n-xs" mr>
                <e-col style="max-width: 180px">
                  <erp-s-field
                      view="lr"
                      label-width="90px"
                      label="Habilitar robô:"
                      icon-help="O robô controla o leilão automaticamente."
                  >
                    <sl-checkbox v-model="model.config.leilao.controleAutomatico"/>
                  </erp-s-field>
                </e-col>
                <e-col>
                  <erp-s-field
                      view="lr"
                      label="Fechamento Simultâneo:"
                      icon-help="Por padrão, recomendamos o fechamento lote a lote. Se habilitar esta função, o fechamento de todos os lotes será simultâneo, respeitando o intervalo em segundos definido no cronômetro. O primeiro lote terá um intervalo definido separadamente."
                  >
                    <sl-checkbox v-model="model.config.leilao.controleSimultaneo"/>
                  </erp-s-field>
                </e-col>
              </e-row>
              <e-row mr>
                <e-col style="max-width: 172px">
                  <erp-s-field
                      view="lr"
                      label-width="90px"
                      label="Habilitar cronômetro:"
                      tag="div"
                      :disabled="model.config.leilao.controleAutomatico"
                  >
                    <sl-checkbox :disabled="model.config.leilao.controleAutomatico" v-model="model.config.leilao.cronometro"/>
                  </erp-s-field>
                </e-col>
                <e-col>
                  <erp-s-field
                      view="lr"
                      label="Cronômetro sempre ativo:"
                      icon-help="O cronômetro no site, painel do arrematante e demais áreas de visualização dos lotes sempre ficará ativo. Até a data do leilão o cronômetro estará em contagem regressiva."
                  >
                    <sl-checkbox v-model="model.config.leilao.cronometroSempreAtivo"/>
                  </erp-s-field>
                </e-col>
              </e-row>

              <e-row class="m-t-n-xs" mr>
                <e-col style="max-width: 180px">
                  <erp-s-field
                      view="lr"
                      label-width="90px"
                      label="Lances automáticos:"
                  >
                    <sl-checkbox v-model="model.config.leilao.permitirLanceAutomatico"/>
                  </erp-s-field>
                </e-col>
                <e-col>
                  <erp-s-field
                      view="lr"
                      label-width="143px"
                      label="Lances antecipados:"
                      icon-help="Permite receber lances antes da data de abertura"
                  >
                    <sl-checkbox v-model="model.config.leilao.permitirLanceAntecipado"/>
                  </erp-s-field>
                </e-col>
              </e-row>
            </e-col>
          </e-row>
          <e-row class="m-t-n-xs" mr>
            <e-col style="max-width: 233px">
              <erp-s-field
                  view="lr"
                  label-width="90px"
                  label="Cronômetro:"
                  icon-help="Tempo do cronômetro regressivo e tempo de renovação do cronômetro sempre que o sistema receber um lance durante o pregão ativo"
              >
                <erp-input type="number" style="max-width: 60px" size="2" v-model="model.config.leilao.timerPregao"/>
              </erp-s-field>
            </e-col>
            <e-col style="min-width: 200px; max-width: 268px">
              <erp-s-field
                  class="full-width"
                  view="lr"
                  label="Tipo do Cronômetro:"
                  label-width="90px"
                  icon-help="Renovar: sempre que o tempo estiver abaixo do tempo do cronômetro, será renovado. Incremental: sempre que o tempo estiver abaixo do cronômetro, será incrementado a mais no tempo atual."
              >
                <erp-select class="full-width" v-model="model.config.leilao.tipoCronometro"
                            :options="[
                                          {label: 'Renovar', value: 0},
                                          {label: 'Incremental', value: 1}
                                      ]"/>
              </erp-s-field>
            </e-col>
          </e-row>

          <div class="form-column-align-center">
            <e-row mr>
              <e-col style="max-width: 190px">
                <erp-s-field
                    view="lr"
                    label-width="90px"
                    :disabled="!model.config.leilao.controleAutomatico"
                    label="Intervalo entre lotes:"
                    icon-help="Tempo que o robô (em segundos) irá adicionar como intervalo para cada lote. Somente em caso de leilão simultâneo."
                >
                  <erp-input :disable="!model.config.leilao.controleAutomatico" type="number" style="max-width: 60px"
                             size="2" v-model="model.config.leilao.timerIntervalo"/>
                </erp-s-field>
              </e-col>
              <e-col>
                <erp-s-field
                    view="lr"
                    :disabled="!model.config.leilao.controleAutomatico"
                    label="Intervalo primeiro lote:"
                    icon-help="Tempo que o robô definirá para o início do pregão do primeiro lote, em segundos."
                >
                  <erp-input :disable="!model.config.leilao.controleAutomatico" type="number" style="max-width: 60px"
                             size="2" v-model="model.config.leilao.controleTempoInicial"/>
                </erp-s-field>
              </e-col>
            </e-row>
          </div>
          <div class="form-column-align-center">
            <e-row mr>
              <e-col style="min-width: 200px">
                <erp-s-field
                    class="full-width"
                    view="lr"
                    label="Tipo do Auditório:"
                    label-width="90px"
                >
                  <erp-select class="full-width" v-model="model.config.leilao.auditorioPadrao"
                              :options="modelosAuditorio"/>
                </erp-s-field>
              </e-col>
              <e-col>
                <erp-s-field
                    view="lr"
                    label="Permitir escolha do auditório:"
                    icon-help="Permite que clientes escolham o auditório de sua preferência."
                >
                  <sl-checkbox v-model="model.config.leilao.permitirEscolhaAuditorio"/>
                </erp-s-field>
              </e-col>
            </e-row>
          </div>

          <div class="form-column-align-center">
            <e-row mr>
              <e-col>
                <erp-s-field
                    view="lr"
                    label="Parcelamento:"
                    label-width="90px"
                >
                  <erp-select v-model="model.config.leilao.permitirParcelamento"
                              :options="[{label: 'Não', value: false}, {label: 'Sim', value: true}]"/>
                </erp-s-field>
              </e-col>
              <e-col>
                <erp-s-field
                    view="lr"
                    label="Máximo de parcelas:"
                >
                  <erp-input v-model="model.config.leilao.parcelamentoQtdParcelas" type="number"/>
                </erp-s-field>
              </e-col>
              <e-col style="max-width: 400px">
                <erp-s-field
                    view="lr"
                    label="Entrada mínima (%):"
                    icon-help="Deixe 0% para divisão em parcelas iguais"
                    helper-position="lb"
                >
                  <erp-input v-model="model.config.leilao.parcelamentoMinimoEntrada" type="number" suffix="%"/>
                </erp-s-field>
              </e-col>
            </e-row>
            <e-row mr>
              <e-col>
                <erp-s-field
                    view="lr"
                    label="Permitir Sublote:"
                    label-width="90px"
                >
                  <erp-select v-model="model.config.leilao.sublote"
                              :options="[{label: 'Não', value: false}, {label: 'Sim', value: true}]"/>
                </erp-s-field>
              </e-col>
              <e-col>
                <erp-s-field
                    view="lr"
                    label="Permitir Direito de Preferência:"
                >
                  <erp-select v-model="model.config.leilao.direitoPreferencia"
                              :options="[{label: 'Não', value: false}, {label: 'Sim', value: true}]"/>
                </erp-s-field>
              </e-col>
            </e-row>
          </div>
        </e-col>
      </e-row>

      <!-- DOCS -->
      <div>
        <div>
          <div class="m-t m-b-lg font-bold">
            <div>Definições para a cobrança</div>
          </div>
          <div class="m-t">
            <leilao-contas v-model="model.config.extra.contas" />
          </div>
        </div>
        <div class="m-t-lg m-b-lg font-bold">
          Templates específicos para este leilão
        </div>
        <e-row mr>
          <e-col>
            <erp-s-field
                view="tl"
                label="Cobrança de Arremate:"
            >
              <documento-select @abrirDoc="abrirDoc" code="comunicacao.arrematante.cobranca-arremate"
                                v-model="model.config.templates.templateCobranca"/>
            </erp-s-field>
          </e-col>
        </e-row>
        <e-row class="m-t" mr>
          <e-col>
            <erp-s-field
                view="tl"
                label="Aviso de Condicional:"
            >
              <documento-select @abrirDoc="abrirDoc" code="comunicacao.arrematante.lote-condicional"
                                v-model="model.config.templates.lanceCodicional"/>
            </erp-s-field>
          </e-col>
        </e-row>
        <e-row class="m-t" mr>
          <e-col>
            <erp-s-field
                view="tl"
                label="Aviso de Baixa Oferta:"
            >
              <documento-select @abrirDoc="abrirDoc" code="comunicacao.arrematante.lote-condicional"
                                v-model="model.config.templates.baixaOferta"/>
            </erp-s-field>
          </e-col>
        </e-row>
        <e-row class="m-t" mr>
          <e-col>
            <erp-s-field
                view="tl"
                label="Aviso de Lance:"
            >
              <documento-select @abrirDoc="abrirDoc" code="comunicacao.arrematante.lance-superado"
                                v-model="model.config.templates.lanceSuperado"/>
            </erp-s-field>
          </e-col>
        </e-row>
        <e-row class="m-t" mr>
          <e-col>
            <erp-s-field
                view="tl"
                label="Nota de Arrematação:"
            >
              <documento-select @abrirDoc="abrirDoc" code="documentos-leilao-notas-arrematacao"
                                v-model="model.config.templates.nota"/>
            </erp-s-field>
          </e-col>
        </e-row>
        <e-row class="m-t" mr>
          <e-col>
            <erp-s-field
                view="tl"
                label="Fatura de Arrematação:"
            >
              <documento-select @abrirDoc="abrirDoc" code="documentos-leilao-recibos-pagamento-lote"
                                v-model="model.config.templates.fatura"/>
            </erp-s-field>
          </e-col>
        </e-row>
        <e-row class="m-t" mr>
          <e-col>
            <erp-s-field
                view="tl"
                label="Recibo de Arremate:"
            >
              <documento-select @abrirDoc="abrirDoc" code="documentos-leilao-recibos"
                                v-model="model.config.templates.recibo"/>
            </erp-s-field>
          </e-col>
        </e-row>
        <e-row class="m-t" mr>
          <e-col>
            <erp-s-field
                view="tl"
                label="Termo de Habilitação:"
            >
              <documento-select @abrirDoc="abrirDoc" code="documentos-leilao-habilitacao"
                                v-model="model.config.templates.termoHabilitacao"/>
            </erp-s-field>
          </e-col>
        </e-row>
        <e-row class="m-t" mr>
          <e-col>
            <erp-s-field
                view="tl"
                label="E-mail de confirmação de Habilitação:"
            >
              <documento-select @abrirDoc="abrirDoc" code="documentos-leilao-termos"
                                v-model="model.config.templates.confirmacaoHabilitacao"/>
            </erp-s-field>
          </e-col>
        </e-row>

        <e-row class="m-t" mr>
          <e-col>
            <erp-s-field
                view="tl"
                label="Diário de Leilão:"
            >
              <documento-select @abrirDoc="abrirDoc" code="documentos-leilao-diario"
                                v-model="model.config.templates.diario"/>
            </erp-s-field>
          </e-col>
        </e-row>

        <e-row class="m-t" mr>
          <e-col>
            <erp-s-field
                view="tl"
                label="Certidão de Publicação:"
            >
              <documento-select @abrirDoc="abrirDoc" code="documentos-leilao-certidao-publicacao"
                                v-model="model.config.templates.certidaoPublicacao"/>
            </erp-s-field>
          </e-col>
        </e-row>

        <e-row class="m-t" mr>
          <e-col>
            <erp-s-field
                view="tl"
                label="Termo de Alienação:"
            >
              <documento-select @abrirDoc="abrirDoc" code="documentos-leilao-termo-alienacao"
                                v-model="model.config.templates.termoAlienacao"/>
            </erp-s-field>
          </e-col>
        </e-row>

        <e-row class="m-t" mr>
          <e-col>
            <erp-s-field
                view="tl"
                label="Auto/Certidão Positivo(a) de Arrematação:"
            >
              <documento-select @abrirDoc="abrirDoc" code="documentos-leilao-auto-positivo"
                                v-model="model.config.templates.autoPositivo"/>
            </erp-s-field>
          </e-col>
        </e-row>

        <e-row class="m-t" mr>
          <e-col>
            <erp-s-field
                view="tl"
                label="Certidão Negativa de Arrematação:"
            >
              <documento-select @abrirDoc="abrirDoc" code="documentos-leilao-auto-negativo"
                                v-model="model.config.templates.autoNegativo"/>
            </erp-s-field>
          </e-col>
        </e-row>

        <e-row class="m-t" mr>
          <e-col>
            <erp-s-field
                view="tl"
                label="Edital:"
            >
              <documento-select @abrirDoc="abrirDoc" code="documentos-leilao-edital"
                                v-model="model.config.templates.edital"/>
            </erp-s-field>
          </e-col>
        </e-row>

        <div class="m-t font-bold m-b">Documentos adicionais</div>

        <e-row v-for="(template, key) in model.config.templatesAdicionais" :key="key" class="m-t b-b" mr>
          <e-col>
            <erp-s-field
                view="tl"
            >
              <erp-input placeholder="Nome" v-model="template.label"></erp-input>
              <br>
              <documento-select @abrirDoc="abrirDoc" code="documentos-leilao-auto-negativo"
                                v-model="template.template"/>
              <br>
              <a @click="removeTemplateAdicional(key)" class="font-10 text-negative">Remover item</a>
            </erp-s-field>
          </e-col>
        </e-row>
        <e-btn @click="addTemplateAdicional" label="Adicionar documento adicional" />
        <!--    <div class="m-t-lg">
              <h2 class="diviser m-b-lg column">
                <div>Definições avançadas das configurações do leilão</div>
                <p>Atenção: Somente use se tiver conhecimento para a operação.</p>
              </h2>
              <div class="m-t">
                <textarea v-model="model.config"></textarea>
              </div>
            </div>-->
        <div class="docs-main">
          <documento ref="docApp"/>
        </div>
      </div>
    </window-body>
  </window-default>
</template>

<script>
import {WindowBody, ErpSField, ErpInput, ErpCheckbox, ErpSelect} from 'uloc-vue-plugin-erp'
import WindowDefault from '../../../../../reuse/window/Default'
import SlCheckbox from "@/reuse/input/Checkbox"
import {
  findClassificacaoLeilao as find,
  newClassificacaoLeilao as _new,
  updateClassificacaoLeilao as update,
} from '../../../../../domain/cadastro/services'
import ERow from '../../../../layout/components/Row'
import ECol from '../../../../layout/components/Col'
import save from '../../../helpers/save'
import LeilaoContas from "components/leiloes/components/include/Contas"
import Documento from "components/documentos/components/Documento"
import DocumentoSelect from "components/documentos/components/include/DocumentoSelect"

let mock = {
  id: null,
  nome: null,
  config: {},
  active: true
}

export default {
  name: 'Window',
  components: {
    ECol, ERow, WindowDefault, WindowBody, ErpSField, ErpInput, ErpCheckbox, ErpSelect, SlCheckbox,
    LeilaoContas,
    Documento,
    DocumentoSelect
  },
  props: ['props'],
  data () {
    return {
      model: this.proccessData(JSON.parse(JSON.stringify(mock)))
    }
  },
  mounted () {
  },
  watch: {
    'model.config.leilao.controleAutomatico'(v) {
      if (v) {
        this.model.config.leilao.cronometro = true
      }
    }
  },
  computed: {
    find () {
      return find
    },
    mock () {
      return mock
    },
    modelosAuditorio () {
      const list = []
      list.push({label: 'Lote a Lote v1', value: 'default'})
      list.push({label: 'Lote a Lote vBlack', value: 'black'})
      list.push({label: 'Cards Lado a Lado v1', value: 'cards-v1'})
      list.push({label: 'Cards Lado a Lado v2', value: 'cards-v2'})
      return list
    }
  },
  methods: {
    update: update,
    new: _new,
    save: save,
    beforeSave (data) {
      data = JSON.parse(JSON.stringify(data))
      if (data.config) {
        data.config = JSON.stringify(data.config)
      }
      return data
    },
    proccessData (data) {
      let cfg = {
        leilao: {
          controleAutomatico: false
        },
        templates: {
          templateCobranca: null
        },
        extra: {
          contas: []
        },
        templatesAdicionais: [],
      }
      if (!data.config) {
        data.config = cfg
      } else {
        data.config = Object.assign(cfg, data.config)
        if (Array.isArray(data.config.leilao)) {
          data.config.leilao = {}
        }
        if (Array.isArray(data.config.templates)) {
          data.config.templates = {}
        }
        if (Array.isArray(data.config.extra)) {
          data.config.extra = {}
        }
        // data.config = JSON.parse(data.config)
      }
      return data
    },
    abrirDoc(doc, e) {
      this.$refs.docApp.show(doc.id)
    },
    addTemplateAdicional () {
      this.model.config.templatesAdicionais.push({
        label: null,
        template: null
      })
    },
    removeTemplateAdicional (k) {
      this.model.config.templatesAdicionais.splice(k, 1)
    }
  }
}
</script>
